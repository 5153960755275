<template>
    <div class="page-product-detail">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><span class="Mousehand" @click="goback">返回</span></el-breadcrumb-item>
                <el-breadcrumb-item>{{ ProductsInfo.goodsTypeName }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ ProductsInfo.goodsNumber }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="product-base display justify-spb">
                <div class="left" style="width: 23%">
                    <el-carousel :interval="3000" indicator-position="none" height="244px">
                        <el-carousel-item v-for="(item,index) in logoimgList" :key="index">
                            <div class="pic">
<!--                                <img :src="item || defaultImg" alt="">-->
                                <el-image fit="cover" :src="item"><div slot="error" class="image-slot"><img :src="defaultImg"></div></el-image>
                            </div>
                        </el-carousel-item>
                    </el-carousel>

                    <div class="usp" v-if="brandInfo.isShow==1">
                        <div class="icon" style="height: auto">
                            <img :src="PPlogoimg" alt="">
                        </div>
                        <div class="text">
                            <div class="text_hidden2">{{PPcontentremarks}}</div>
                            <div class="brand fts12 cursorPointer" style="color: #ea6100;" @click="goPPdetail(ProductsInfo.brandId)">
                                了解更多<i class="el-icon-arrow-right ftwt"></i>
                            </div>
                        </div>
                    </div>
                    <div class="download mat20">
                        <div class="item cursorPointer" @click="dialogVisible = true">
                            <i></i>
                            <span>质检报告 (COA)</span>
                        </div>
                        <div class="item cursorPointer" @click="getgoodsmsdsList()">
                            <i></i>
                            <span>材料安全数据 (MSDS)</span>
                        </div>
                    </div>
                </div>
                <div class="right" style="width: 75%">
                    <div class="label">
                        <span>品牌：</span>
                        <em>{{PPname}}</em>
                    </div>
                    <h1 class="name">{{ ProductsInfo.name }}</h1>
                    <p class="desc">{{ ProductsInfo.nameEn }}</p>

<!--                    <h3>其他描述</h3>-->
                    <div class="label mab10 align-flex-start">
                        <span>其他描述：</span>
                        <em class="flex1 c-1c8f8e">{{ ProductsInfo.introduction }}</em>
                    </div>
                    <div class="label">
                        <span style="margin-right: 28px">别</span><span>名：</span>
                        <em>{{ ProductsInfo.nameAlias }}</em>
                    </div>
                    <div class="info-row">
                        <div class="label">
                            <span>产品分类：</span>
                            <em style="flex: 1">{{ ProductsInfo.goodsTypeName }}</em>
                        </div>
                        <div class="label">
                            <span>分子式：</span>
                            <em style="flex: 1" v-html="ProductsInfo.molecularFormula"></em>
                        </div>
                        <div class="label">
                            <span>分子量：</span>
                            <em style="flex: 1">{{ ProductsInfo.formulaWeight }}</em>
                        </div>
                        <div class="label">
                            <span>国家标准：</span>
                            <em style="flex: 1">{{ ProductsInfo.nationalStandard }}</em>
                        </div>
                        <div class="label">
                            <span>cas号：</span>
                            <em style="flex: 1" class="c-1c8f8e cursorPointer ftwt" @click="goSearchResult(ProductsInfo.casNumber)">{{ ProductsInfo.casNumber }}</em>
                        </div>
                        <div class="label">
                            <span>MDL号：</span>
                            <em style="flex: 1">{{ ProductsInfo.mdlNumber }}</em>
                        </div>
                        <div class="label">
                            <span>保存温度：</span>
                            <em style="flex: 1">{{ ProductsInfo.storageTemperature }}</em>
                        </div>
                        <div class="label">
                            <span class="">监管条件：</span>
                            <em class="text_hidden1 c-red" style="flex: 1">{{ ProductsInfo.dangerNature }}</em>
                        </div>
                    </div>
                    <div class="g-table-wrap">
                        <table class="g-table">
                            <thead>
                            <tr>
                                <td>产品编号</td>
                                <td>包装规格</td>
                                <td>库存</td>
                                <td>会员价(￥)</td>
                                <td>数量</td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <p>{{ ProductsInfo.goodsNumber }}</p>
                                </td>
                                <td>
                                    {{ ProductsInfo.specification }}
                                </td>
                                <td>
                                    <p v-if="ProductsInfo.goodsStockList&&ProductsInfo.goodsStockList.length>0" >
                                        <span v-for="(item,index) in ProductsInfo.goodsStockList" :key="index" style="display: block;">{{ item.stockName }} {{ calculateStock(item) }}</span>
                                    </p>
                                    <p v-else>订货</p>

                                </td>
                                <td>
                                    <p>
                                        <span class="xiahuaX" v-if="ProductsInfo.normalPrice==0"  @click.stop="inquiry(ProductsInfo)">询价</span>
                                        <span v-else>
                                            <span class="price">￥{{islogin? (ProductsInfo.customerPrice < ProductsInfo.discountPrice ? ProductsInfo.customerPrice: ProductsInfo.customerPrice > ProductsInfo.discountPrice ? ProductsInfo.discountPrice : ProductsInfo.normalPrice) : (ProductsInfo.normalPrice > ProductsInfo.discountPrice ? ProductsInfo.discountPrice : ProductsInfo.normalPrice) }}</span>
                                            <span class="old-price"v-if="islogin && (ProductsInfo.customerPrice < ProductsInfo.normalPrice || ProductsInfo.discountPrice < ProductsInfo.normalPrice)">￥{{ ProductsInfo. normalPrice}}</span>
                                        </span>

                                    </p>
                                </td>
                                <td>
                                    <div class="tool">
                                        <el-input-number v-model="table.n1" :min="1"></el-input-number>
                                    </div>
                                </td>
                                <td>
                                    <div class="g-btns">
                                        <div @click="goOrder(ProductsInfo,table.n1)" style="color: #fff !important;" class="g-btn ">立即购买</div>
                                        <div @click="joinCart(ProductsInfo,table.n1)" class="g-btn white"> 加入购物车</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-bot">
            <div class="wp">
                <div class="box">
                    <div class="g-tit">
                        <h3>产品推荐</h3>
                    </div>
                    <div class="product-swi-wrap">
                        <div class="product-swi">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide product-item" style="margin-right: 34px !important" v-for="(item,index) in TJgoodsList" :key="index" @click="getgoodsInfoDetail(item.id)">
                                    <div class="pic">
<!--                                        <img :src="item.pictureUrl || defaultImg" alt="">-->
                                        <el-image style="height: 100%;width: 100%" fit="cover" :src="item.pictureUrl"><div slot="error" class="image-slot"><img :src="defaultImg"></div></el-image>

                                    </div>
                                    <div class="text text_hidden2">
                                        <h3 class="text_hidden2" style="height: 45px">{{ item.name }}</h3>
                                        <div class="label text_hidden1 wid90">
                                            <span>品牌:   </span>
	                                        <span class="ftwt400">{{ item.brandName }}</span>
                                        </div>
                                        <div class="label text_hidden1" style="max-width: 150px">
                                            <span>产品编号:   </span>
                                            <span class="ftwt400">{{ item.goodsNumber }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
                <div class="box">
                    <div class="g-tit">
                        <h3>基本信息</h3>
                    </div>
                    <div class="base-info">
                        <div class="row">
                            <span>产品名称</span>
                            <p>{{ ProductsInfo.name }}</p>
                        </div>
                        <div class="row">
                            <span>英文名称</span>
                            <p>{{ ProductsInfo.nameEn }}</p>
                        </div>
                        <div class="row">
                            <span>别名</span>
                            <p>{{ ProductsInfo.nameAlias }}</p>
                        </div>
                        <div class="row">
                            <span>纯度信息</span>
                            <p>{{ ProductsInfo.purity }}</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="g-tit">
                        <h3>一般描述</h3>
                    </div>
                    <div class="text-content">
                        <p v-html="ProductsInfo.describeText"></p>
                    </div>
                </div>
                <div class="box">
                    <div class="g-tit">
                        <h3>历史浏览</h3>
                    </div>
                    <div class="his-list" style="display: flex;flex-flow: wrap;">
                        <div @click="getgoodsInfoDetail(item.id)" class="product-item" v-for="(item,index) in footlist" :key="index">
                            <div class="pic">
                                <el-image style="height: 100%;width: 100%" fit="cover" :src="item.pictureUrl"><div slot="error" class="image-slot"><img :src="defaultImg"></div></el-image>

                            </div>
                            <div class="text">
                                <h3 class="text_hidden2" style="height: 45px">{{ item.name }}</h3>
                                <div class="label text_hidden1 wid90">
                                    <span>品牌:   </span>
	                                <span class="ftwt400">{{ item.brandName }}</span>
                                </div>
                                <div class="label text_hidden1" style="height: 40px;max-width: 150px">
                                    <span>产品编号:   </span>
	                                <span class="ftwt400">{{ item.goodsNumber }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            title="批号"
            :visible.sync="dialogVisible"
            width="30%">
            <el-form label-width="80px">
                <el-form-item label="批号">
                    <el-input v-model="cas"> </el-input>
                </el-form-item>
            </el-form>
            <div class="g-btn small" style="margin: 0 auto;" @click="getgoodscoaList">查询</div>

        </el-dialog>

        <div class="m-pop" v-if="showPop">
            <div class="pop-con" style="max-width: 1200px;">
                <div class="title" style="border-bottom: none;height: 40px;">
                    <h3></h3>
                    <div class="close" @click="showPop = false"></div>
                </div>
                <div class="inner-con" style="padding-top: 0;">
                    <div class="g-table-wrap">
                        <table class="g-table">
                            <thead>
                            <tr>
                                <td>产品编号</td>
                                <td>产品描述</td>
                                <td>品牌</td>
                                <td>CAS号</td>
                                <td>包装规格</td>
                                <td>售价(￥)</td>
                                <td>会员价(￥)</td>
                                <td style="text-align: center;">库存</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,index) in inquiryData" :key="index">
                                <td>
                                    <p>{{ item.goodsNumber }}</p>
                                </td>
                                <td>
                                    <p>{{item.name}}  {{item.nameEn}}</p>
                                    <p><span class="gray">监管条件：</span> <span class="red">{{ item.dangerNature }}</span></p>
                                </td>
                                <td>
                                    {{ item.brandName}}
                                </td>
                                <td>
                                    <div class=" ftwt">{{ item.casNumber }}</div>
                                </td>
                                <td>
                                    {{ item.specification }}
                                </td>
                                <td>询价</td>
                                <td>询价</td>
                                <td>
                                    <p v-for="(item1,index1) in item.goodsStockList" :key="index1">{{ item1.stockName }}
                                        {{ item1.goodsCount > 50?'>50':item1.goodsCount }}</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="form-row">
                        <el-form ref="inquiryForm" :model="inquiryForm" :rules="inquiryRules" label-width="80px">
                            <el-form-item label="联系人" prop="username">
                                <el-input v-model="inquiryForm.username"></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话" prop="phone">
                                <el-input v-model="inquiryForm.phone"></el-input>
                            </el-form-item>
                            <el-form-item label="单位名称" prop="companyName">
                                <el-input v-model="inquiryForm.companyName"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="g-btn form-btn" @click="submitInquiry">提交</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Swiper from "swiper";
import {
    goodsInfoDetail, goodscoaList,goodscasDetail,
    goodsmsdsList, consultgoodsSave,
} from "@/api/Product";
import {brandinfoDetail, goodsInfoPage} from "@/api/home";
import {joincartSave} from "@/api/transaction";

import Bus from "@/utils/bus";
import {userDetail} from "@/api/account";

export default {
    name: "ProductInfo",
    data() {
        return {
            cas:'',
            table: {
                n1: 1
            },
            dialogVisible: false,

            ProductsInfo:{}, //产品信息
            logoimgList:[], // 商品图片展示
	        brandInfo:"", // 品牌信息
            PPname:"", // 品牌名称
            PPlogoimg:"", // 品牌logo
            PPcontentText:"", // 品牌描述
            PPcontentremarks:"", // 品牌简介

            TJgoodsList:[],
            isfirst:true,
            current:1, // 页数

            footlist:[], // 历史足迹
			islogin:localStorage.getItem('Token')?true:false, // 是否登录

            showPop: false,
            inquiryData:[], //询价数据
            inquiryForm: {
                username: '',
                phone: '',
                companyName	: '',
                goodsId:'',
            },
            inquiryRules: {
                username: [{required: true, trigger: 'blur', message: '必填项'}],
                phone: [{required: true, trigger: 'blur', message: '必填项'}],
                companyName: [{required: true, trigger: 'blur', message: '必填项'}],
            },

            isuserinfo:false, // 用户信息是否完善


        }
    },
    mounted() {
        this.getuserDetail() // 查询用户信息是否完善
        this.getgoodsInfoDetail(this.$route.query.id)
    },
    // 关闭定时器
    destroyed() {
        clearInterval(this.time)
    },
    methods: {
        goback(){
            let query = this.$route.query
            delete query.id
            this.$router.push({ path: '/SearchResult', query: { ...query, isSearch: true } }) //
        },
         // 计算库存
         calculateStock(it) {
            if (it.stockId == '0') {
                if (it.goodsCount == 0) {
                    return '订货';
                } 
                return it.goodsCount > 50 ? '>50' : '<50';
            } else {
                if (it.goodsCount == 0) {
                    return it.remarks == 'import stock' || it.remarks == '' ? '订货' : it.remarks;
                } else {
                    return it.goodsCount > 50 ? '>50' : '<50';
                }
            }
        },
        //询价
        inquiry(item){
            this.showPop = true
            this.inquiryData=[]
            this.inquiryData.push(item)
            this.inquiryForm.goodsId=item.id
        },
        // 立即购买
        goOrder(item,num){
            // 需判断是否有token并且未过期
            if(localStorage.getItem('Token') && !this.$utils.isTokenExpired()){
                if(this.isuserinfo){ // 用户信息完善
                    // if(this.ProductsInfo.normalPrice==0){
                    //     this.$message({
                    //         type: 'warning',
                    //         message: "询价商品不能进行购买！",
                    //         duration: 3000
                    //     });
                    //     return
                    // }
	                let goodsNums=JSON.stringify(num).split('')
	                this.$router.push({path:'/SettleAccounts',query: {goodsIdlist:item.id,goodsNums:JSON.stringify(goodsNums)}}) //
                }else{
                    this.$message({
                        type: 'warning',
                        message: "请先完善个人信息！",
                        duration: 3000
                    });
                    this.$router.push({path:'/vip',query:{type:"完善信息"}}) // 去完善个人信息
                }

            }else{
                Bus.$emit('re-login', '未登录')
            }
        },
        // 点击加入购物车
        joinCart(item,num){
            console.log(item,num)
			if(!localStorage.getItem('Token') || this.$utils.isTokenExpired()){
				Bus.$emit('re-login', '未登录')
				return
			}
			if(!this.isuserinfo){
				this.$message({
					type: 'warning',
					message: "请先完善个人信息！",
					duration: 3000
				});
				this.$router.push({path:'/vip',query:{type:"完善信息"}}) // 去完善个人信息
				return
			}
            // if(item.normalPrice==0){
            //     this.$message({
            //         type: 'warning',
            //         message: "询价商品不能加入购物车！",
            //         duration: 3000
            //     });
            //     return
            // }
            let param={
                goodsId:item.id,
                customerId:localStorage.getItem('customId'),
                count:num
            }
            joincartSave(param).then((res) => {
                if (res.data.code==200) {
                    this.$message({
                        type: 'success',
                        message: "已加入购物车",
                        duration: 2000
                    });
                    Bus.$emit('addCart', '加购物车')
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 2000
                    });
                }
            })
        },
        //停留页面2秒的时候进行调用
        postFoot(goodinfo) {

            this.time = setTimeout(() => { // 停留页面大于2秒
                // localStorage.removeItem('historyFoot')
				console.log(JSON.parse(decodeURIComponent(localStorage.getItem('historyFoot'))))
				let footList=JSON.parse(decodeURIComponent(localStorage.getItem('historyFoot'))) || []
				let isexist=false
				footList.forEach((i,index)=>{
					if(i.id==goodinfo.id){
						isexist=true // 表示存在
						footList.splice(index,1)
						footList.unshift(goodinfo)
					}
				})
				// this.footlist=footList
				if(!isexist){ // 历史浏览记录小于8个并且不重复
					if(footList.length==8){
						footList.pop() // 删除最后一个数组
					}
					footList.unshift(goodinfo)
					localStorage.setItem('historyFoot',encodeURIComponent(JSON.stringify(footList))) // 缓存历史足迹
				}
				this.footlist=footList

				console.log(JSON.parse(decodeURIComponent(localStorage.getItem('historyFoot'))))
                // localStorage.setItem('historyFoot',footList.length>0?footList:null) // 缓存历史足迹
            }, 0)
        },
        //去品牌详情
        goPPdetail(id){
            // query传参数跳转用path (/BrandDetail类似get,url后面会显示参数) params传参数跳转用name (类似post)
            this.$router.push({path:'/BrandDetail',query: {id:id}}) //
        },
        // 去搜索页
        goSearchResult(casNumber){
            this.$router.push({path:'/SearchResult',query: {casNumber:casNumber}}) //
        },
        // 初始化推荐产品swiper
        initSwiper: function () {
            this.$nextTick(function(){
                let that=this
                var swiper = new Swiper('.product-swi', {
                    navigation: {
                        nextEl: ' .swiper-button-next',
                        prevEl: ' .swiper-button-prev',
                    },
                    slidesPerView: 4,
                    spaceBetween: 14,
                    breakpoints: {
                        767: {  //当屏幕宽度大于等于320
                            slidesPerView: 1,
                            spaceBetween: 10
                        },
                        959: {  //当屏幕宽度大于等于768
                            slidesPerView: 2,
                            spaceBetween: 20
                        },
                    },
                    on:{
                        transitionEnd: function(){ // 拖动到最后一个的回调
                            //你的事件
                            if(this.activeIndex+4 == that.TJgoodsList.length){ //最左边当前index+当前一页展示个数=总个数 表示拖动到了最后一个
                                that.current++
                                that.getgoodsInfoList() // 查询下一页推荐产品
                            }
                            console.log(this.activeIndex,that.TJgoodsList.length);//切换结束时，告诉我现在是第几个slide
                            // console.log(swiper)
                        },
                    },
                });

            })

        },

        // ------------------------------------接口请求
        //产品详情
        getgoodsInfoDetail(id){
            goodsInfoDetail({id:id}).then((res) => {
                if (res.data.code==200) {
                    this.logoimgList=[] // 清空商品图片
                    let data=res.data.data
                    data.goodsStockList = data.goodsStockList.filter(it=> it.stockId=='0' || it.goodsCount>0 || it.sync)
                    data.customerPrice=parseFloat(data.customerPrice)
                    data.discountPrice=parseFloat(data.discountPrice)
                    data.normalPrice=parseFloat(data.normalPrice)
                    this.ProductsInfo=data
                    this.ProductsInfo.describeText=this.$utils.showHtml(res.data.data.describeText)
                    this.logoimgList.push(...(data.pictureUrl.split(',')))
                    this.postFoot(res.data.data)//开启定时器 缓存历史记录
                    // this.getgoodscasDetail() // 查询cas详情


                    document.documentElement.scrollTop=0
                    this.getbrandinfoDetail(this.ProductsInfo.brandId) //品牌详情

                    this.current=1
                    this.TJgoodsList=[]
                    this.getgoodsInfoList(1) //推荐产品列表
                }else{
	                this.$message({
		                type: 'error',
		                message: res.data.message,
		                duration: 2000
	                });
                }

            }).catch(error=>{
                console.log(error)
                if(error.response.data.code !=200){
                    this.$message({
                        type: 'error',
                        message: error.response.data.message,
                        duration: 2000
                    });
                }
            })
        },
        // 查询商品关联coa详情
        getgoodscasDetail(){
            let param={
                // batchNumber:this.cas, // 批号
                // brandId:this.ProductsInfo.brandId, // 品牌id
                casNumber:this.ProductsInfo.casNumber,
                goodsNumber:this.ProductsInfo.goodsNumber, // 产品编号
            }
            goodscasDetail(param).then((res) => {
                if (res.data.code==200) {
                    console.log('cas详情',res.data.data)
                    let data=res.data.data
                    if(data.logoUrl){
                        this.logoimgList.unshift(data.logoUrl)
                    }
                }
            })
        },
        // 品牌详情
        getbrandinfoDetail(id){
            brandinfoDetail({id:id}).then((res) => {
                if (res.data.code==200) {
                	this.brandInfo=res.data.data // 商品关联的品牌信息
                    this.PPlogoimg=res.data.data.logoUrl
                    this.PPcontentText=this.$utils.showHtml(res.data.data.introText)
                    this.PPcontentremarks=res.data.data.remarks

                    // this.PPcontentText=removeHTMLTag(this.PPcontentText)
                    console.log(this.PPcontentText)
                    this.PPname=res.data.data.name
                }

            })

            /*移除HTML标签代码*/
            function removeHTMLTag(str) {
                str = str.replace(/<\/?[^>]*>/g,''); //去除HTML tag
                str = str.replace(/[ | ]*\n/g,'\n'); //去除行尾空白
                //str = str.replace(/\n[\s| | ]*\r/g,'\n'); //去除多余空行
                str=str.replace(/ /ig,'');//去掉
                return str;
            }
        },

        // 获取用户信息
        async getuserDetail(){
            if(localStorage.getItem('userInfo')){
                console.log(JSON.parse(localStorage.getItem('userInfo')).userId)
                let userId=JSON.parse(localStorage.getItem('userInfo')).userId
                userDetail({userId:userId}).then((res) => {
                    if (res.data.code==200) {
                        let data=res.data.data
                        if(data.companyName && data.email){ // 工作单位跟邮箱
                            this.isuserinfo=true  // 用户信息完善
                        }
                    }

                })
            }
        },

        // 查询coa下载文件
        getgoodscoaList(){
            if(!this.cas){ // 未填写产品批号
                this.$message({
                    type: 'warning',
                    message: "请填写批号",
                    duration: 2000
                });
                return
            }
            let param={
                batchNumber:this.cas, // 批号
                brandId:this.ProductsInfo.brandId, // 品牌id
                goodsNumber:this.ProductsInfo.goodsNumber, // 产品编号
            }
            goodscoaList(param).then((res) => {
                if (res.data.code==200) {
                    this.dialogVisible = false //关闭弹窗
                    if(res.data.data.records && res.data.data.records[0]){
                        window.open(res.data.data.records[0].fileUrl)
                    }else{
                        this.$message({
                            type: 'warning',
                            message: '文件不存在！',
                            duration: 1500
                        });
                    }
                }
            })
        },
        // 查询msds下载文件
        getgoodsmsdsList(){
            let param={
                brandId:this.ProductsInfo.brandId, // 品牌id
                goodsNumber:this.ProductsInfo.goodsNumber, // 产品编号
            }
            goodsmsdsList(param).then((res) => {
                if (res.data.code==200) {
                    if(res.data.data.records && res.data.data.records[0]){
                        window.open(res.data.data.records[0].fileUrl)
                    }else{
                        this.$message({
                            type: 'warning',
                            message: '文件不存在！',
                            duration: 1500
                        });
                    }
                }
            })
        },
        getgoodsInfoList(type){ // 查询推荐产品
            let param={
                size:10,
                current:this.current,
                casNumber:this.ProductsInfo.casNumber,
                // goodsTypeId:this.ProductsInfo.goodsTypeId,
                // brandId:this.ProductsInfo.brandId,
            }
            goodsInfoPage(param).then((res) => {
                if (res.data.code==200) {
                    if(res.data.data.records.length>0){
                        this.TJgoodsList.push(...res.data.data.records)
                        console.log(this.TJgoodsList)
                        this.initSwiper()

                        // if(this.isfirst){ // 只第一次执行
                        //     console.log(this.isfirst)
                        //     this.isfirst=false
                        // }
                    }else{
                        this.current-- // 没查到 页数减少
                    }
                }

            })
        },

        // 提交询价
        submitInquiry(){
            this.$refs.inquiryForm.validate(valid => {
                if (valid) {
                    if(!(/^1[3456789]\d{9}$/.test(this.inquiryForm.phone))){
                        this.$message({
                            type: 'warning',
                            message: '请填写正确的手机号！',
                            duration: 1500
                        });
                        return
                    }
                    var params = {
                        // refreshToken: localStorage.getItem('refresh_Token'),
                        username: this.inquiryForm.username,
                        phone: this.inquiryForm.phone,
                        companyName: this.inquiryForm.companyName,
                        goodsId: this.inquiryForm.goodsId,
                    };
                    console.log('提交询价')
                    consultgoodsSave(params).then((res) => {
                        if(res.data.code==200){
                            this.showPop = false
                            this.$message({
                                type: 'success',
                                message: "提交成功",
                                duration: 2000
                            });
                        }else{
                            this.$message({
                                type: 'error',
                                message:res.data.message,
                                duration: 2000
                            });
                        }
                    })
                }
            })

        },

    }
}
</script>

<style scoped lang="scss">
.g-btns a {
    text-decoration: none;
}
.page-product-detail{
    font-family: 'Noto Sans SC';
    .info-row{
        //font-family: 'myFont';
        .label{
	        display: flex !important;
            align-items: flex-start !important;
        }
    }
}
body{
    //font-family: 'myFont';
}

</style>
