import { render, staticRenderFns } from "./SearchProductDetail.vue?vue&type=template&id=aff1200c&scoped=true"
import script from "./SearchProductDetail.vue?vue&type=script&lang=js"
export * from "./SearchProductDetail.vue?vue&type=script&lang=js"
import style0 from "./SearchProductDetail.vue?vue&type=style&index=0&id=aff1200c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aff1200c",
  null
  
)

export default component.exports